import React, { useState, useEffect, Fragment, useMemo } from 'react'

import TablaAsistentes from './TablaAsistentes'

import { Box, Column, Tab } from 'rbx'
import feathers from "../feathers-client"
import moment from "moment";

const Lista = () => {

  const [ attendees, setAttendees ] = useState({ "culto-divino": [], "culto-joven" : [] })
  const [ currentDate ] = useState(() => moment().day( 6 ).format( "DD/MM/YYYY" ))
  const [ tabActive, setTabActive ] = useState("CD")
  const attendeesClient = useMemo(() => feathers.service( 'attendees' ), [])

  useEffect(() => {
    getAttendees()
  }, [])

  const sortLista = ( a, b ) => {
    if ( 
      a.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      > 
      b.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      ) {
      return 1;
    }
    if (
      a.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      < 
      b.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim()
      ) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }

  const getAttendees = () => {
    attendeesClient.find({
      query: {
        date: currentDate
      }
    })
    .then( res => {
      const CD = res.filter( a => a.program === "culto-divino" )
      const CJ = res.filter( a => a.program === "culto-joven" )
      const CD_arr = CD.map(( att, index ) => {
        const reg = /(\W|^)(Fam.|Fam|fam.|fam|familia|Familia)(\W|$)/
        return { 
          ...att, idx: index + 1,
          family: att.family.replace(reg, "")
        }
      })
      const CJ_arr = CJ.map(( att, index ) => {
        const reg = /(\W|^)(Fam.|Fam|fam.|fam|familia|Familia)(\W|$)/
        return { 
          ...att, idx: index + 1,
          family: att.family.replace(reg, "")
        }
      })
      // setAttendees({ "culto-divino": CD.sort( sortLista ), "culto-joven": CJ.sort( sortLista ) })
      setAttendees({ "culto-divino": CD_arr, "culto-joven": CJ_arr })
    })
    .catch( err => console.log( "ERR get attendees =>", err ))
  }

  const handleClickCheckbox = ( id, checked, program ) => {
    const newChecked = checked === "1" ? "0" : "1"
    attendeesClient.patch(id, { checked: newChecked })
    const att_temp = [ ...attendees[ program ] ]
    const idx = att_temp.findIndex( a => a._id === id )
    att_temp[idx] = {
      ...att_temp[idx],
      checked: newChecked
    }
    setAttendees({
      ...attendees,
      [program]: att_temp
    })
  }

  return (
    <Fragment>
      {/* TABS */}
      <Tab.Group>
        <Tab active={ tabActive === "CD" } onClick={ () => setTabActive("CD") } >Culto Divino</Tab>
        <Tab active={ tabActive === "CJ" } onClick={ () => setTabActive("CJ") }>Culto Joven</Tab>
      </Tab.Group>

      {/* TABLES */}
      <Column.Group multiline>
        {
          tabActive === "CD" && 
          <Column size={ 12 }>
            <Box>
              <TablaAsistentes title={ `Culto Divino - ${ currentDate }` } attendees={ attendees["culto-divino"] } handleClickCheckbox={ handleClickCheckbox } />
            </Box>
          </Column>
        }
        
        {
          tabActive === "CJ" && 
          <Column size={ 12 }>
            <Box>
              <TablaAsistentes title={ `Culto Joven - ${ currentDate }` } attendees={ attendees["culto-joven"] } handleClickCheckbox={ handleClickCheckbox } />
            </Box>
          </Column>
        }
        
      </Column.Group>
      

      
    </Fragment>
  )

}

export default Lista
