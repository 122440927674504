import React from 'react'

import DownloadTable from './DownloadTable'

import MaterialTable from 'material-table'
import { Title, Checkbox } from 'rbx'

const TablaAsistentes = ({ title, attendees, handleClickCheckbox }) => {

  const columns = [
    { title: '#', field: 'idx' },
    { title: 'Nombre', field: 'fullname' },
    { title: 'Edad', field: 'age' },
    { title: 'Familia', field: 'family' },
    { 
      title: 'Asistencia', 
      field: 'checked',  
      render: rowData => { return <Checkbox checked={ rowData.checked === "1" } onChange={ () => handleClickCheckbox( rowData._id, rowData.checked, rowData.program ) } />}
    }
  ]
  
  const options = {
    pageSizeOptions: [10, 20, 50],
    pageSize: 50
  }
  
  
  return (
    <React.Fragment>
      <Title> { title } </Title>
      <DownloadTable title={ title } attendees={ attendees } />

      <MaterialTable
        columns={ columns }
        data={ attendees }
        title=""
        options={ options }
      />

    </React.Fragment>
  )}

export default TablaAsistentes
