import React, { Fragment, useState, useEffect } from "react"
import { 
  Box, 
  Title, 
  Field, 
  Select, 
  Column, 
  Label, 
  Control,
  Input,
  Button,
  Message,
  Radio,
  Help
} from "rbx"
import feathers from "../feathers-client"
import moment from "moment";
import Swal from 'sweetalert2'

const ATTENDEE_DEFAULT = {
  fullname: "",
  age: "",
  family: "",
  checked: 0
}

const MAX_ATTENDEES = 60;
const MAX_ATTENDEES_YOUTHS = 30;
const MAX_ATTENDEES_OLDERS = 20;

const MAP_SYMPTOMS = {
  "tos": 0,
  "fiebre": 1,
  "respirar": 2,
  "dolor": 3,
  "nariz": 4,
  "cabeza": 5
}

const Registro = () => {
  const [ attendee, setAttendee ] = useState( ATTENDEE_DEFAULT )
  const [ currentAttendees, setCurrentAttendees ] = useState([ ])
  const [ program, setProgram ] = useState( "viernes" )
  const [ available, setAvailable ] = useState({ VIERNES: 0, MANANA: 0, TARDE: 0 })
  const [ currentDate ] = useState(() => moment().day( 6 ).format( "DD/MM/YYYY" ))
  const [ lastSabath ] = useState(() => moment().day( 6 ).subtract( 7, 'd' ).format( "DD/MM/YYYY" ))
  const [ symptoms, setSymptoms ] = useState([ "0", "0", "0", "0", "0", "0" ])
  const [ loading, setLoading ] = useState( false )
  const [ validateName, setValidateName ] = useState({ form: { error: false, color: 'default' }, button: true })
  const [ validateLastname, setValidateLastname ] = useState({ form: { error: false, color: 'default' }, button: true })

  useEffect(() => {
    getCurrentAttendees()
  }, [])

  useEffect(() => {
    const viernes = currentAttendees.filter(attendee => attendee.program === "viernes")
    const manana = currentAttendees.filter(attendee => attendee.program === "sabado-manana")
    const tarde = currentAttendees.filter(attendee => attendee.program === "sabado-tarde")
    setAvailable({
      VIERNES: MAX_ATTENDEES - viernes.length, 
      MANANA: MAX_ATTENDEES - manana.length, 
      TARDE: MAX_ATTENDEES - tarde.length
    })
  }, [ currentAttendees ])

  const getCurrentAttendees = () => {
    const attendeesClient = feathers.service( 'attendees' )
    attendeesClient.find({
      query: {
        date: currentDate
      }
    })
    .then( res => setCurrentAttendees( res ) )
    .catch( err => console.log( "ERR get attendees =>", err ))
  }

  const handleChangeProgram = ( e ) => {
    setProgram( e.target.value )
  }

  const handleChangeInput = ( e ) => {
    setAttendee({
      ...attendee,
      [e.target.name]: e.target.value
    })
    if( e.target.name === "fullname" ) {
      if( e.target.value.trim().split(" ").length < 3 ) {
        setValidateName({ form: { error: true, color: 'danger' }, button: true })
      } else {
        setValidateName({ form: { error: false, color: 'default' }, button: false })
      }
    }
    if( e.target.name === "family" ) {
      const reg = /(\W|^)(Fam.|Fam|fam.|fam|familia|Familia)(\W|$)/
      const input = e.target.value.trim().replace(reg, "").split(" ")
      if( input.length < 2 ) {
        setValidateLastname({ form: { error: true, color: 'danger' }, button: true })
      } else {
        setValidateLastname({ form: { error: false, color: 'default' }, button: false })
      }
    }
  }

  const handleRadios = ( e ) => {
    const tempSymptoms = [ ...symptoms ]
    tempSymptoms[MAP_SYMPTOMS[e.target.name]] = e.target.value
    setSymptoms(tempSymptoms)
  }

  const validateSpace = async () => {
    if( program === "viernes" ) {
      if( available.VIERNES <= 0 ) {
        await Swal.fire({
          text: 'No hay espacio disponible para el Viernes',
          icon: 'error',
          confirmButtonColor: '#3085d6'
        })
        return false
      }
    }
    if( program === "sabado-manana" ) {
      if( available.MANANA <= 0 ) {
        await Swal.fire({
          text: 'No hay espacio disponible para el Sábado de Mañana',
          icon: 'error',
          confirmButtonColor: '#3085d6'
        })
        return false
      }
    }
    if( program === "sabado-tarde" ) {
      if( available.TARDE <= 0 ) {
        await Swal.fire({
          text: 'No hay espacio disponible para el Sábado de Tarde',
          icon: 'error',
          confirmButtonColor: '#3085d6'
        })
        return false
      }
    }
    return true
  }

  const validateAttendance = async () => {
    setLoading( true )
    const attendeesClient = feathers.service( 'attendees' )
    const last_week_all = await attendeesClient.find({
      query: {
        date: lastSabath,
        program: program
      }
    })

    const current_week = currentAttendees.filter(att => 
      att.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      === 
      attendee.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      && att.program === program )

    const last_week = last_week_all.filter(att => 
      att.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      === 
      attendee.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() )

    if( current_week.length !== 0  ) {
      await Swal.fire({
        text: 'El asistente ya está registrado en esta semana',
        icon: 'error',
        confirmButtonColor: '#3085d6'
      })
      return false
    }
    if( last_week.length !== 0 ) {
      if( program === "culto-joven" && ( attendee.age <= 35 || attendee.age >= 13 ) ) {
        return true
      }
      await Swal.fire({
        text: 'Ya asististe el Sábado pasado. Te esperamos el próximo Sábado.',
        icon: 'error',
        confirmButtonColor: '#3085d6'
      })
      return false
    }

    return true
  }

  const handleSave = async () => {

    const hasSymptoms = symptoms.indexOf("si") > -1

    if( hasSymptoms ) {

      await Swal.fire({
        text: 'Debido a sus síntomas no puede asistir al culto.',
        icon: 'error',
        confirmButtonColor: '#3085d6'
      })
      window.location.reload()

    } else {
      const isThereSpace = await validateSpace()

      if( isThereSpace ) {

        setLoading( false )

        const attendeesClient = feathers.service( 'attendees' )

        attendeesClient.create({
          ...attendee,
          date: currentDate,
          program
        })
        .then( async (res) => {
          getCurrentAttendees()

          await Swal.fire({
            text: '¡Te has registrado correctamente!',
            icon: 'success',
            confirmButtonColor: '#3085d6'
          })
          window.location.reload()
        })
        .catch( async err => {
          console.log("ERR create attendee =>", err)
          await Swal.fire({
            text: 'Hubo un problema. Intente de nuevo más tarde,',
            icon: 'error',
            confirmButtonColor: '#3085d6'
          })
          window.location.reload()
        })
      } else {
        window.location.reload()
      }
    }
  }

  return (
    <Fragment>

      <Box>
        <Title>Registro para la Santa Cena 2021</Title>

        <Message color="warning" className="indicaciones-generales">
          <Message.Header>
            <p>Indicaciones generales</p>
          </Message.Header>
          <Message.Body>
            <p><b>Si se registra junto a su familia, llene en el espacio indicado los apellidos de la familia, por ejemplo: Fam. Gonzalez Martínez. De esta manera podremos acomodarlos en la misma banca.</b></p>

            <br />
            <p> <b>Horarios</b>
              <ul>
                <li>Viernes: 7:00 PM</li>
                <li>Sábado de Mañana: 9:00 AM</li>
                <li>Sábado de Tarde: 5:00 PM</li>
              </ul>
            </p>
            <br />
            <p>- Favor de estar presentes media hora antes del culto.</p>
            <p>- Al llegar a la iglesia siga las indicaciones de los diáconos y diaconisas</p>
            <p>- Tenga en cuenta el protocolo para asistir: el uso del cubre bocas, la sana distancia, no aglomeración, evitar los abrazos y besos, etc.</p>
            <br />
              <Title size={ 5 }>
                Espacios disponibles para el Viernes: { available.VIERNES }
              </Title>
              <Title size={ 5 }>
                Espacios disponibles para el Sábado de Mañana: { available.MANANA }
              </Title>
              <Title size={ 5 }>
                Espacios disponibles para el Sábado de Tarde: { available.TARDE }
              </Title>
          </Message.Body>
        </Message>

        <Column.Group>
          <Column>

            <Field>
              <Label>Horario</Label>
              <Control>
              <Select.Container>
                <Select onChange={ handleChangeProgram } >
                  <Select.Option value="viernes"> Viernes </Select.Option>
                  <Select.Option value="sabado-manana"> Sábado de Mañana  </Select.Option>
                  <Select.Option value="sabado-tarde"> Sábado de Tarde  </Select.Option>
                </Select>
              </Select.Container>
              </Control>
            </Field>

          </Column>
        </Column.Group>

        {/* FORM NAME - AGE - FAMILY */}
        <Column.Group>
        <Column>

          <Field>
            <Label>Nombre Completo</Label>
            <Control>
              <Input
                color={ validateName.form.color }
                name="fullname"
                type="text" 
                placeholder="Nombre Completo" 
                value={ attendee.fullname } 
                onChange={ handleChangeInput }
              />
            </Control>
            {
              validateName.form.error && <Help color="danger">Debe ingresar su nombre completo</Help>
            }
          </Field>

        </Column>

        <Column>

          <Field>
            <Label>Edad</Label>
            <Control>
              <Input
                name="age"
                type="number" 
                placeholder="Edad" 
                value={ attendee.age } 
                onChange={ handleChangeInput }
              />
            </Control>
          </Field>

        </Column>

        <Column>

          <Field>
            <Label>Apellidos de Familia</Label>
            <Control>
              <Input
                color={ validateLastname.form.color }
                name="family"
                type="text" 
                placeholder="Fam. Pinales Jaquez" 
                value={ attendee.family } 
                onChange={ handleChangeInput }
              />
            </Control>
            {
              validateLastname.form.error && <Help color="danger">Debe ingresar los dos apellidos</Help>
            }
          </Field>

        </Column>

        </Column.Group>

        {/* CUSTIONARIO SÍNTOMAS */}
        <Column.Group multiline className={ "custionario-sintomas" } >
            
          <Column size={ 12 }>
            <Title size={ 5 }> En los últimos 7 días, ¿usted ha presentado algunos de los siguientes signos y síntomas? </Title>
          </Column>
          
          <Column size={ 3 }>

            <Field horizontal>
              <Field.Label>
                <Label>Tos</Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Label>
                      <Radio name="tos" value="si" onChange={ handleRadios } /> Sí
                    </Label>
                    <Label>
                      <Radio name="tos" value="no" onChange={ handleRadios } /> No
                    </Label>
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal>
              <Field.Label>
                <Label>Fiebre</Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Label>
                      <Radio name="fiebre" value="si" onChange={ handleRadios } /> Sí
                    </Label>
                    <Label>
                      <Radio name="fiebre" value="no" onChange={ handleRadios }  /> No
                    </Label>
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal>
              <Field.Label>
                <Label>Dificultad para respirar</Label>
              </Field.Label>
              <Field.Body> 
                <Field>
                  <Control>
                    <Label>
                      <Radio name="respirar" value="si" onChange={ handleRadios }  /> Sí
                    </Label>
                    <Label>
                      <Radio name="respirar" value="no" onChange={ handleRadios }  /> No
                    </Label>
                  </Control>
                </Field>
              </Field.Body>
            </Field>

          </Column>
        
          <Column size={ 3 } >

            <Field horizontal>
              <Field.Label>
                <Label>Dolor en articulaciones</Label>
              </Field.Label>
              <Field.Body> 
                <Field>
                  <Control>
                    <Label>
                      <Radio name="dolor" value="si" onChange={ handleRadios }  /> Sí
                    </Label>
                    <Label>
                      <Radio name="dolor" value="no" onChange={ handleRadios } /> No
                    </Label>
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal>
              <Field.Label>
                <Label>Flujo de secreción por la nariz</Label>
              </Field.Label>
              <Field.Body> 
                <Field >
                  <Control>
                    <Label>
                      <Radio name="nariz" value="si" onChange={ handleRadios }  /> Sí
                    </Label>
                    <Label>
                      <Radio name="nariz" value="no" onChange={ handleRadios }  /> No
                    </Label>
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal>
              <Field.Label>
                <Label>Dolor de cabeza</Label>
              </Field.Label>
              <Field.Body> 
                <Field >
                  <Control>
                    <Label>
                      <Radio name="cabeza" value="si" onChange={ handleRadios }  /> Sí
                    </Label>
                    <Label>
                      <Radio name="cabeza" value="no" onChange={ handleRadios }  /> No
                    </Label>
                  </Control>
                </Field>
              </Field.Body>
            </Field>
          
          </Column>
          
          <Column size={ 12 }>
            <Button
              state={ loading === true ? "loading" : "" }
              disabled={ 
                symptoms.indexOf("0") > -1 
                || attendee.fullname === "" 
                || attendee.age === "" 
                || loading
                || validateName.button
              }
              color="link"
              onClick={ handleSave } 
            > 
              Registrar 
            </Button>
          </Column>

        </Column.Group>

      </Box>

    </Fragment>
  )
}

export default Registro
